@import '~antd/dist/antd.dark.css';

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  color: #FFF;
  background: #EBF2F5;
}

body, input, button, textarea {
  font: 600 18px Bitter, sans-serif;
}