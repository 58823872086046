#page-map {
  width: 100vw;
  height: 100vh;

  position: relative;
  display: flex;
}

#map-container {
  width: 100vw;
  height: 100vh;

  position: relative;
  display: flex;
}

#side-container {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 999;

  padding: 0px;

  width: 422px;
  height: 100vh;
}


#side-container-top {
  border: 0px;
  background-image: url("../../assets/images/bg-main-top.png");
  background-position: top -94px right 0;
  background-repeat: no-repeat;
  padding-top: 12px;
  width: 100%;
  height: 122px;
}

#side-container-top img {
  margin: 0 auto;
  text-align: center;
  display: block;
}

#side-container-body {
  background-image: url("../../assets/images/bg-main-body.png");
  background-position: top right;
  border: 0px solid red;
}

#side-container-body header {
  border: 0;
  background-image: url("../../assets/images/bg-solid.png");
  background-position: top right;
  background-repeat: no-repeat;

  width: 408px;
  height: 36px;
}

#side-container-body header h2 {
  font-size: 26px;
  font-weight: 600;
  line-height: 28px;
  
  padding: 12px 0 0 12px;
}

#side-container-body main {
  border: 0px solid blue;
  background-image: url("../../assets/images/bg-main-solid-body.png");
  background-repeat: repeat;

  padding: 12px 12px 0 12px;

  width: 408px;
  height: calc(100vh - 158px);
}

#side-container-body main p.sub {
  font-size: 16px;
  font-weight: 600;
  margin-top: 20px;
}

#side-container-body main .ant-form-item {
  margin-bottom: 12px !important;
}

#side-container-body main .ant-tabs-nav {
  margin: 0 !important;
}

.ant-tabs-tab.ant-tabs-tab-active, .ant-card {
  background: rgba(0, 0, 0, 0.35) !important;
}

.ant-card-head p {
  line-height: 18px;
  font-size: 16px;
  font-weight: 600 !important;
}

.ant-card p {
  line-height: 18px;
  font-size: 14px;
  font-weight: 200;
}

.ant-card .zipPhone {
  display: flex;
  justify-content: space-between;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #fff !important;
}

#page-map .map-popup .leaflet-popup-content-wrapper {
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 17.2868px 27.6589px 41.4884px rgba(23, 142, 166, 0.16);
  border-radius: 20px;
}

#page-map .map-popup .leaflet-popup-content {
  color: #0089a5;
  font-size: 20px;
  font-weight: 700;
  margin: 8px 12px;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

#page-map .map-popup .leaflet-popup-content a {
  width: 40px;
  height: 40px;
  /*background: #000000;*/
  box-shadow: 17.2868px 27.6589px 41.4884px rgba(23, 142, 166, 0.16);
  border-radius: 12px;

  display: flex;
  justify-content: center;
  align-items: center;
}

#page-map .map-popup .leaflet-popup-tip-container {
  top: 50%;
  left: 0;
}

#page-map .map-popup .leaflet-popup-tip {
  display: none;
}

#page-map .leaflet-container {
  z-index: 5;
}
